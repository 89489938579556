<template>
  <div class="breadcrumb-wrapper">
    <b-breadcrumb
      v-if="!isBreadcrumbLoading && breadcrumb.length > 0"
      noEllipsis
    >
      <b-breadcrumb-item to="/">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="breadcrumb[0].to">
        {{ getText(breadcrumb[0].text) }}
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-if="showTooltip"
        id="tooltip-button-2"
        @click="toggleTooltip"
      >
        ...
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="breadcrumb[breadcrumb.length - 2].to"
        v-if="breadcrumb.length > 2"
      >
        {{ getText(breadcrumb[breadcrumb.length - 2].text) }}
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="breadcrumb[breadcrumb.length - 1].to"
        v-if="breadcrumb.length > 1"
      >
        {{ getText(breadcrumb[breadcrumb.length - 1].text) }}
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-tooltip v-if="showTooltip" target="tooltip-button-2">
      <div class="breadcrumb-wrapper">
        <b-breadcrumb v-if="!isBreadcrumbLoading">
          <b-breadcrumb-item
            v-for="item in breadcrumb.slice(1, -2)"
            :key="item.index ? item.index : item.text"
            :active="item.active"
            :to="item.to"
            class="item-b"
          >
            {{ getText(item.text) }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BTooltip } from "bootstrap-vue";

export default {
  components: { BBreadcrumb, BBreadcrumbItem, BTooltip },
  data() {
    return {
      breadcrumb: [],
      isBreadcrumbLoading: false,
      showTooltip: false
    };
  },
  created() {
    this.fetchEventsList();
    setInterval(this.fetchEventsList, 300);
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    fetchEventsList() {
      if (this.$route.meta && Array.isArray(this.$route.meta.breadcrumb)) {
        this.breadcrumb = this.$route.meta.breadcrumb;
      } else {
        this.breadcrumb = [];
      }
      this.showTooltip = this.breadcrumb.length > 3;
    },

    getText(text) {
      if (!text) return text;

      const match = text.match(/Breadcrumb_(.+)/);
      if (match) return this.$t("Breadcrumb_." + match[1]);
      else return text;
    },
    toggleTooltip(event) {
      event.preventDefault();
      this.showTooltip = !this.showTooltip;
    },
    handleClickOutside(event) {
      if (this.showTooltip && !event.target.closest(".tooltip")) {
        this.showTooltip = false;
      }
    }
  },
  watch: {
    "$store.state.mapLoader.is_breadcrumb_loading"(val) {
      this.isBreadcrumbLoading = val;
    }
  }
};
</script>

<style lang="scss">
.b-skeleton-breadcrumb {
  height: 20px;
  width: 250px;
  margin-bottom: -2px;
  margin-left: 7px;
}

.tooltip-inner {
  .breadcrumb .breadcrumb-item {
    color: rgb(250, 248, 248) !important;

    a {
      color: rgb(250, 248, 248) !important;
    }
  }
}

.tooltip-inner {
  max-width: none;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
}

.tooltip.bs-tooltip-auto[x-placement^="top"],
.tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  margin: 0 4px;
}

.tooltip.bs-tooltip-auto[x-placement^="left"],
.tooltip.bs-tooltip-auto[x-placement^="right"] {
  margin: 4px 0;
}

.feather-icon {
  color: #fff;
}
// .breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item + .breadcrumb-item:before  {
//     background-image: url(../../../assets/images/unit-icon/menu/breadcrumb.svg) !important;
// }
</style>
