<template>
  <div>
    <b-modal
      id="modal-active-sessions"
      :title="$t('activeSessions.Title')"
      centered
      hide-footer
      size="md"
      header-class="active-sessions-header"
      modal-class="active-sessions-modal"
      @hidden="modalClosed"
    >
      <div>
        {{ $t("activeSessions.sessionBrief") }}
      </div>

      <div>
        <div
          v-for="session in activeSessions"
          :key="session.session_id"
          :class="
            'd-flex align-items-center mt-1 session-card' +
            (session.is_current ? '-active' : '')
          "
        >
          <div
            class="d-flex align-items-center justify-content-between flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <!-- <div><Phone class="phone" /></div> -->
              <div>
                <div :class="'device-heading' ? 'device-heading-active' : ''">
                  {{ session.browser_name }}
                </div>
                <div class="device" v-if="!session.is_current">
                  {{ $t("activeSessions.LastActivity") }}
                  {{
                    session.last_active
                      ? DateTimeConvert(
                          session.last_active,
                          constants.DATE_TIME_FORMAT
                        )
                      : ""
                  }}
                </div>
                <div class="device-active" v-else>
                  {{ $t("activeSessions.CurrentDevice") }}
                </div>
              </div>
            </div>
            <a
              href="#"
              class="btn btn-link text-right"
              @click="oneSession(session.session_id)"
              >{{ $t("activeSessions.RevokeButton") }}</a
            >
          </div>
        </div>
      </div>
      <div class="revoke-all-btn" v-b-modal.modal-1>
        <a href="#" class="btn-link">
          {{ $t("activeSessions.RevokeAll") }}
        </a>
      </div>
    </b-modal>

    <b-modal
      id="modal-1"
      class="d-flex justify-content-center"
      :title="$t('activeSessions.ConfirmLogout')"
      size="sm"
      centered
      hide-footer
    >
      <div class="text-center">
        {{ $t("activeSessions.ConfirmMsg") }}
      </div>
      <div class="d-flex justify-content-center mb-64 mtt-50">
        <b-button variant="outline-primary" class="ml-2" @click="closeModal">{{
          $t("activeSessions.CancelBtn")
        }}</b-button>
        <b-button
          class="mr-2 ml-2"
          variant="primary"
          :disabled="logoutInProgress"
          @click="revokeAllsessions()"
          >{{ $t("activeSessions.Logout") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal, BButton } from "bootstrap-vue";
import WebBrowser from "@/assets/images/new-icon/web-browser.svg";
import Phone from "@/assets/images/new-icon/phone.svg";
import Web from "@/assets/images/new-icon/web.svg";
import AuthService from "@/libs/api/auth-service";
import { mapMutations } from "vuex";
import validators from "@/utils/validators";
import constants from "@/utils/constants";
export default {
  props: {
    activeSessionsOpen: {
      type: Boolean,
      required: true
    }
  },
  components: { BLink, BModal, BButton, WebBrowser, Phone, Web },
  data() {
    return {
      loggedUserId: localStorage.getItem("USER_LOGIN_ID"),
      accountName: localStorage.getItem("USER_ACCOUNT_NAME"),
      roleName: localStorage.getItem("USER_ACCOUNT_ROLE_NAME"),
      activeSessions: [],
      sessionId: "",
      logoutInProgress: false
    };
  },
  mounted() {
    this.getActiveSessions();
  },
  watch: {
    activeSessionsOpen(isOpen) {
      if (isOpen) {
        this.getActiveSessions();
      }
    }
  },
  methods: {
    ...mapMutations("user", ["setUserAccount"]),
    closeModal() {
      this.$bvModal.hide("modal-1");
      this.sessionId = "";
    },
    modalClosed() {
      this.$emit("activeSessions");
    },
    handleDataUpdate(data) {
      this.getProfileDetails();
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      document.body.classList.remove(
        "blue-layout",
        "pink-layout",
        "red-layout",
        "orange-layout",
        "yellow-layout",
        "green-layout",
        "teal-layout",
        "cyan-layout"
      );
      document.body.classList.add(className);
      localStorage.setItem("savedClass", className);
    },
    async revokeAllsessions() {
      try {
        this.logoutInProgress = true;
        let response = await new AuthService().logoutSessions({
          ...(this.sessionId
            ? {
                session_id: this.sessionId
              }
            : { logout_all_session: true })
        });

        if (this.sessionId == this.getCurrentSession()) {
          this.setUserAccount({});
          localStorage.clear();
          this.$router.push({ name: "login" });
        }
        if (this.sessionId) {
          this.$bvModal.hide("modal-1");
          this.getActiveSessions();
          this.sessionId = "";
        } else {
          this.setUserAccount({});
          localStorage.clear();
          this.$router.push({ name: "login" });
        }
        this.logoutInProgress = false;
      } catch {
        this.logoutInProgress = false;
      }
    },
    oneSession(sessionId) {
      this.$bvModal.show("modal-1");
      this.sessionId = sessionId;
    },

    getCurrentSession() {
      return localStorage.getItem("sessionId");
    },
    async getActiveSessions() {
      try {
        let response = await new AuthService().getActiveSessions({});
        // console.log(response);
        if (response.data.list && response.data.list.length) {
          this.activeSessions = response.data.list
            .map((session) => {
              session.is_current =
                this.getCurrentSession() == session.session_id;
              return session;
            })
            .sort((a, b) => {
              if (a.is_current && !b.is_current) {
                return -1;
              } else if (!a.is_current && b.is_current) {
                return 1;
              } else {
                return 0;
              }
            });
        }
      } catch (error) {
        console.error("Error fetching active sessions:", error);
      }
    },
    DateTimeConvert(date) {
      return validators.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    async getUserAccounts(isReload, isSwitch) {
      try {
        if (this.$router.name === "forgot-password-II") {
          return false;
        }
        this.show = true;
        const el = document.body;
        el.className = localStorage.getItem("savedClass");
        el.classNameSkin = localStorage.getItem("vuexy-skin");
        if (el.classNameSkin == "dark") {
          document.body.classList.add("dark-layout");
        }

        let response = await new AccountService().getUserAccounts({});
        if (localStorage.getItem("ACCOUNT_ID")) {
          localStorage.setItem(
            "OLD_USER_ACCOUNT",
            localStorage.getItem("ACCOUNT_ID")
          );
        }

        if (
          response &&
          response.data &&
          response.data &&
          response.data.length
        ) {
          this.show = false;
          this.accounts = response.data;
          this.accounts = this.accounts.map((l) => {
            l.variant = validators.getRandomBgColor();
            l.avtar_txt = validators.userAvatarTxt(l.account_name);

            return l;
          });
          const accountIds = this.accounts.map((e) => e.account_id);
          if (
            localStorage.getItem("USER_LOGIN_ID") === this.loggedUserId &&
            localStorage.getItem("ACCOUNT_ID") &&
            accountIds.includes(localStorage.getItem("ACCOUNT_ID")) &&
            !isSwitch
          ) {
            const lastSelectedAccount = this.accounts.find(
              (e) => e.account_id === localStorage.getItem("ACCOUNT_ID")
            );
            if (lastSelectedAccount && lastSelectedAccount.account_id) {
              localStorage.setItem(
                "USER_ACCOUNT_ID",
                lastSelectedAccount.account_id
              );

              localStorage.setItem(
                "ACCOUNT_ID",
                lastSelectedAccount.account_id
              );
              localStorage.setItem(
                "USER_ACCOUNT_NAME",
                lastSelectedAccount.account_name
              );
              this.accountName = lastSelectedAccount.account_name;
              if (this.loggedUserId) {
                localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
              }
              localStorage.setItem(
                "USER_ACCOUNT_TYPE",
                lastSelectedAccount.type
              );
              store.commit("account/setAccountType", lastSelectedAccount.type);

              this.whiteLabeling(lastSelectedAccount);
              this.isOldSelectedId = lastSelectedAccount.account_id;

              this.selectedUserAccount(lastSelectedAccount);
            }
          } else if (this.accounts && this.accounts.length === 0) {
            if (localStorage.getItem("USER_PERMISSIONS")) {
              localStorage.removeItem("USER_PERMISSIONS");
              this.$router.go();
            }
            // this.$router.push({ name: "home" }).catch((e) => {});
            //this.$router.go();
          } else if (this.accounts && this.accounts.length <= 1) {
            if (this.accounts && this.accounts.length === 1) {
              localStorage.setItem(
                "USER_ACCOUNT_ID",
                this.accounts[0].account_id
              );
              localStorage.setItem("ACCOUNT_ID", this.accounts[0].account_id);
              localStorage.setItem(
                "USER_ACCOUNT_NAME",
                this.accounts[0].account_name
              );
              if (this.loggedUserId) {
                localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
              }

              localStorage.setItem("USER_ACCOUNT_TYPE", this.accounts[0].type);

              store.commit("account/setAccountType", this.accounts[0].type);
              this.whiteLabeling(this.accounts[0]);
              this.isOldSelectedId = this.accounts[0].account_id;
              this.accountName = this.accounts[0].account_name;
            }
            this.$bvModal.hide("modal-sm-switch-account");
            this.selectedUserAccount(this.accounts[0]);
            this.showAcceptInvitationScreen();
          } else {
            if (isReload) {
              this.$bvModal.show("modal-sm-switch-account");
            }
          }
          this.accounts.forEach((a) => {
            let accountId;
            if (localStorage.getItem("USER_ACCOUNT_ID")) {
              accountId = localStorage.getItem("USER_ACCOUNT_ID");
            }

            if (a.account_id === accountId) {
              this.isSelectedId = accountId;
              this.isOldSelectedId = accountId;
              this.whiteLabeling(a);
              localStorage.setItem(
                "USER_SUB_ACCOUNTS_RESTRICTED",
                a.restrict_sub_accounts
              );
            }
          });
          // this.$router.push({ name: "home" }).catch((e) => {});
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        } else {
          this.show = false;
          if (localStorage.getItem("USER_PERMISSIONS")) {
            localStorage.removeItem("USER_PERMISSIONS");
            this.$router.go();
          }
          //   this.$router.go();
          this.$router.push({ name: "user-invitations" }).catch((e) => {});

          //  this.showAcceptInvitationScreen();
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    mouseover(id) {
      this.isSelectedId = id;
    },
    mouseout(id) {
      this.isSelectedId = this.isOldSelectedId;
    },
    getCurrentAccount() {
      return localStorage.getItem("USER_ACCOUNT_TYPE");
    },
    getFilteredLists(filter, response) {
      response.data = Object.keys(response.data)
        .filter((key) => !filter.includes(key))
        .reduce((obj, key) => {
          obj[key] = response.data[key];
          return obj;
        }, {});
    },
    async selectedUserAccount(account, isRedirect) {
      const accountId = localStorage.getItem("OLD_USER_ACCOUNT");
      const requestData = {};
      if (
        !accountId ||
        accountId != account.account_id ||
        !localStorage.getItem("SESSION_LOGIN")
      ) {
        const newSession = uuidv4();
        const oldSession = localStorage.getItem("SESSION_LOGIN");
        localStorage.setItem("SESSION_LOGIN", newSession);
        if (oldSession) {
          requestData.old_session = oldSession;
        }

        requestData.new_session = newSession;
        requestData.account_id = account.account_id;
        if (accountId) {
          requestData.old_account_id = accountId;
        }
      }
      localStorage.setItem("USER_ACCOUNT_ID", account.account_id);
      localStorage.setItem(
        "USER_SUB_ACCOUNTS_RESTRICTED",
        account.restrict_sub_accounts
      );
      localStorage.setItem("ACCOUNT_ID", account.account_id);
      localStorage.setItem("USER_ACCOUNT_TYPE", account.type);

      store.commit("account/setAccountType", account.type);
      localStorage.setItem("USER_ACCOUNT_ROLE_NAME", account.role);
      localStorage.setItem("USER_ACCOUNT_NAME", account.account_name);
      localStorage.setItem("USER_ACCOUNT_ROLE_ID", account.main_role);
      if (this.loggedUserId) {
        localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
      }

      this.whiteLabeling(account);
      this.isOldSelectedId = account.account_id;
      this.accountName = account.account_name;
      // call select account api.
      let response = await new AccountService().selectAccount({
        account_id: account.account_id,
        ...requestData
      });
      if (response && response.data) {
        if (localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true") {
          if (
            ["DEALER", "CONSUMER"].includes(
              localStorage.getItem("USER_ACCOUNT_TYPE")
            )
          ) {
            this.getFilteredLists(["account", "account_devices"], response);
          }
        } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
          //  this.getFilteredLists(["devices"], response);
        }

        acl.parseAndStoreAbilities(response.data, false, true);
        this.$bvModal.hide("modal-sm-switch-account");
        if (isRedirect) {
          if (
            this.checkAbility({
              action: constants.LABELS.MENU,
              subject: "units"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            const query = localStorage.getItem("USER_ACCOUNT_ID")
              ? { account_id: localStorage.getItem("USER_ACCOUNT_ID") }
              : null;
            this.$router
              .push({
                name: "unit-map",
                query
              })
              .catch((err) => {});
            setTimeout(() => {
              this.$router.go();
            }, 200);
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER"
          ) {
            this.$router.push({
              name: "home"
            });
            setTimeout(() => {
              this.$router.go();
            }, 100);
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASH,
              subject: "widgets"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            this.$router.push({
              name: "dashboard"
            });
            setTimeout(() => {
              this.$router.go();
            }, 100);
          } else {
            try {
              Menus.forEach((element) => {
                if (
                  this.checkAbility({
                    action: element.action,
                    subject: element.resource
                  }) &&
                  element.route
                ) {
                  throw element;
                } else {
                  if (element && element.children && element.children.length) {
                    element.children.forEach((child) => {
                      if (
                        this.checkAbility({
                          action: child.action,
                          subject: child.resource
                        })
                      ) {
                        throw child;
                      }
                    });
                  }
                }
              });
            } catch (e) {
              if (e.route) {
                this.$router.push({ name: e.route });
                setTimeout(() => {
                  this.$router.go();
                }, 100);
              } else {
                // localStorage.setItem("TEMP_URL", "user-invitations");
                this.$router.push({ name: "user-invitations" });
                setTimeout(() => {
                  this.$router.go();
                }, 100);
                // if (oldPermission && oldPermission !== newPermission) {
                //   this.$router.go();
                // } else {
                //   this.$router.push({ name: "user-invitations" });
                // }
              }
            }
          }
        }

        // this.showAcceptInvitationScreen();
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async getProfileDetails() {
      try {
        if (!localStorage.getItem("accessToken")) {
          return false;
        }

        this.show = true;
        let response = await new AuthService().getUserProfile({});

        if (response && response.data) {
          this.show = false;
          this.setUserAccount(response.data);
          localStorage.setItem(
            "USER_PROFILE_DETAILS",
            JSON.stringify(response.data)
          );
          this.loggedUserId = response.data.id;
          this.showAcceptInvitationScreen();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    whiteLabeling(account) {
      localStorage.setItem(
        "USER_ACCOUNT_DETAILS",
        JSON.stringify(Object.assign({}, account))
      );
      if (account && account.white_labeling) {
        if (account.white_labeling.theme) {
          this.toggleBodyClass(null, account.white_labeling.theme);
        }
        if (account.white_labeling.lang && !localStorage.getItem("lang")) {
          this.$i18n.locale = account.white_labeling.lang;
          localStorage.setItem("lang", this.$i18n.locale);
          localeChanged();
        }
        store.commit("device/setIcons", []);
      }
    },
    async logout() {
      await new AuthService().logout({
        session_id: localStorage.getItem("sessionId"),
        token: localStorage.getItem("accessToken")
      });
      this.setUserAccount({});
      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    async joinWithUserInvite(action) {
      try {
        this.show = true;
        const body = {
          token: this.inviteData.token,
          action: action
        };
        let response = await new AccountService().joinUserWithInvite(body);

        if (response && response.data) {
          this.show = false;
          const inviteMessage =
            action === "ACCEPT"
              ? this.$t("user.InvitationAccept")
              : this.$t("user.InvitationDecline");

          this.$toast({
            component: ToastificationContent,
            props: {
              title: inviteMessage,
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          localStorage.removeItem("inviteData");
          this.isInvitation = false;
          this.getUserAccounts(1, 0);
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          this.isInvitation = false;
          localStorage.removeItem("inviteData");
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            text: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    showAcceptInvitationScreen() {
      const inviteData =
        localStorage.getItem("inviteData") &&
        JSON.parse(localStorage.getItem("inviteData"));
      const user =
        localStorage.getItem("USER_PROFILE_DETAILS") &&
        JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
      if (
        user &&
        inviteData &&
        user.email.toLowerCase() === inviteData.email.toLowerCase()
      ) {
        this.isInvitation = true;
        this.$bvModal.show("modal-accept-invite");
      }
    }
  }
};
</script>
<style lang="scss">
.session-card-active {
  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 6px;
  margin-top: 10px;

  .device-heading-active {
    font-size: 17px;
    font-weight: 500;
    color: var(--primary);
  }
  .device-active {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
  }
  a {
    color: var(--primary);
  }
  .btn-link {
    color: var(--primary);
  }
}
.dark-layout {
  .session-card {
    border: 1px solid var(--darkgray);
  }
}
.session-card {
  padding: 10px;
  border: 1px solid var(--gray-605);
  border-radius: 6px;
  margin-top: 10px;
  .device-heading {
    font-size: 17px;
    font-weight: 500;
  }
  .device {
    font-size: 14px;
    font-weight: 500;
  }
  .phone {
    margin-left: 6px;
    margin-right: 18px;
  }
  a {
    color: var(--primary);
    &:hover {
      color: var(--primary);
    }
  }
  .btn-link {
    color: var(--primary);
  }
}
.revoke-all-btn {
  margin-top: 15px;
  font-size: 17px;
  a {
    color: var(--primary);
  }
  .btn-link {
    color: var(--primary);
  }
}
#modal-active-sessions___BV_modal_title_.modal-title {
  font-size: 20px;
  margin-left: auto;
}
#modal-1___BV_modal_title_.modal-title {
  font-size: 20px;
  margin-left: auto;
}
button.btn.custom-button-width {
  min-width: 173px !important;
}
@media only screen and (max-width: 991px) {
  [dir] .modal .modal-body {
    padding: 1rem;
  }
  .active-sessions-header {
    padding-right: 6px;
  }
  .active-sessions-modal {
    .modal-body {
      max-height: 65vh;
      overflow-y: auto !important;
    }
  }
}
</style>
