<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb
          class="only-account"
          :class="{
            'account-breadcrumb':
              isDealersRoute ||
              isConsumersRoute ||
              isSubDealersRoute ||
              isSubConsumerRoute
          }"
        />
      </slot>
      <div class="content-body mobile-content-body min-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    AppBreadcrumb
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
      isDealersRoute: false,
      isConsumersRoute: false,
      isSubDealersRoute: false,
      isSubConsumerRoute: false
    };
  },
  watch: {
    $route(to, from) {
      this.isDealersRoute = window.location.href.includes("dealers");
      this.isConsumersRoute = window.location.href.includes("consumers");
      this.isSubDealersRoute = window.location.href.includes("sub-dealer");
      this.isSubConsumerRoute = window.location.href.includes("sub-consumer");
    }
  },
  mounted() {
    this.isDealersRoute = window.location.href.includes("dealers");
    this.isConsumersRoute = window.location.href.includes("consumers");
    this.isSubDealersRoute = window.location.href.includes("sub-dealer");
    this.isSubConsumerRoute = window.location.href.includes("sub-consumer");
  }
};
</script>

<style lang="scss">
.only-account {
  display: none;
}
@media only screen and (max-width: 991px) {
  .only-account {
    &.account-breadcrumb {
      display: block;
      margin-bottom: 12px;
    }
  }
}
</style>
