var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered)
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('DefaultLogo')],1)],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle toggle-cls-arrrow"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20","id":"toggleMenu"},on:{"click":_vm.toggleCollapsed}})],1)],1)]),_c('b-tooltip',{attrs:{"variant":"primary","triggers":"hover","target":"toggleMenu"}},[_vm._v(_vm._s(_vm.$t("tooTip.toggleMenu")))])],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main custom-navigation-outer",attrs:{"items":_vm.navMenuItems}}),_c('div',[_c('b-link',{staticClass:"user-menu-link",attrs:{"href":_vm.computedLink,"target":"_blank"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('ourWikiGuide')),expression:"$t('ourWikiGuide')",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"InfoIcon","size":"20"}}),_c('div',{staticClass:"user-menu-text"},[_vm._v(_vm._s(_vm.$t("usermanual")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }