var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('b-nav-item',{on:{"click":_vm.hideToolTip}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
        title:
          _vm.selectedTheme === 'dark'
            ? _vm.$t('tooTip.dayMode')
            : _vm.selectedTheme === 'light'
            ? _vm.$t('tooTip.nightMode')
            : _vm.selectedTheme === 'auto'
            ? _vm.$t('tooTip.autoMode')
            : _vm.$t('tooTip.dayMode'),
        customClass: 'my-tooltip-nav'
      }),expression:"{\n        title:\n          selectedTheme === 'dark'\n            ? $t('tooTip.dayMode')\n            : selectedTheme === 'light'\n            ? $t('tooTip.nightMode')\n            : selectedTheme === 'auto'\n            ? $t('tooTip.autoMode')\n            : $t('tooTip.dayMode'),\n        customClass: 'my-tooltip-nav'\n      }",modifiers:{"hover":true,"top":true}}],attrs:{"id":_vm.selectedTheme == 'dark'
          ? 'light'
          : _vm.selectedTheme == 'light'
          ? 'auto'
          : _vm.selectedTheme == 'auto'
          ? 'dark'
          : 'light'}},[_c('feather-icon',{attrs:{"size":"21","icon":((_vm.selectedTheme == 'dark'
            ? 'Sun'
            : _vm.selectedTheme == 'light'
            ? 'Moon'
            : _vm.selectedTheme == 'auto'
            ? 'Settings'
            : 'Sun') + "Icon")}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }