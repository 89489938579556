var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content content",class:[
    { 'show-overlay': _vm.$store.state.app.shallShowOverlay },
    _vm.$route.meta.contentClass
  ]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper",class:_vm.contentWidth === 'boxed' ? 'container p-0' : null},[_vm._t("breadcrumb",[_c('app-breadcrumb',{staticClass:"only-account",class:{
          'account-breadcrumb':
            _vm.isDealersRoute ||
            _vm.isConsumersRoute ||
            _vm.isSubDealersRoute ||
            _vm.isSubConsumerRoute
        }})]),_c('div',{staticClass:"content-body mobile-content-body min-body"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_vm._t("default")],2)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }