<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered)
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <DefaultLogo />
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle toggle-cls-arrrow">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                id="toggleMenu"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
        <b-tooltip variant="primary" triggers="hover" target="toggleMenu">{{
          $t("tooTip.toggleMenu")
        }}</b-tooltip>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main custom-navigation-outer"
      />
      <div>
        <b-link :href="computedLink" class="user-menu-link" target="_blank">
          <feather-icon
            icon="InfoIcon"
            size="20"
            v-b-tooltip.hover.top="$t('ourWikiGuide')"
          />
          <div class="user-menu-text">{{ $t("usermanual") }}</div>
        </b-link>
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg, BTooltip, VBTooltip } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import { shouldShowMenuItem } from "@/libs/acl/routeProtection";
import Logo from "@/assets/images/logo/logo.svg";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import constants from "@/utils/constants";
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BTooltip,
    Logo,
    DefaultLogo,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true
    }
  },
  computed: {
    computedLink() {
      const lang = this.$i18n.locale || "en";
      return `${constants.HELP_CENTNER_LINK}/${lang}/home`;
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned"
        ? "ArrowRightCircleIcon"
        : "ArrowLeftCircleIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage
    };
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.toggle-cls-arrrow {
  height: 30px;
  width: 30px;
  box-shadow: 0 4px 8px 0 $rgb_black_6;
  align-items: center;
  justify-content: center;
  display: flex !important;
  border-radius: 30px;
  svg {
    margin-right: 0px !important;
  }
}
.custom-navigation-outer {
  min-height: calc(100% - 45px);
}
.user-menu-link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  color: #625f6e;
  &:hover {
    color: #625f6e;
  }
}
.user-menu-text {
  padding-left: 17px;
  font-size: 1.1rem;
}
.dark-layout .user-menu-link {
  color: #d0d2d6;
  &:hover {
    color: #d0d2d6;
  }
}
.menu-collapsed {
  .expanded {
    .user-menu-link {
      justify-content: flex-start;
      padding: 0 30px;
    }
    .user-menu-text {
      display: block;
    }
  }
  .user-menu-link {
    padding: 0 28px 0 20px;
    justify-content: center;
  }
  .user-menu-text {
    display: none;
  }
}
</style>
<style lang="scss">
.tooltip {
  z-index: 99999 !important;
}
</style>
