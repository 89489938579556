<template>
  <span>
    <b-nav-item @click="hideToolTip">
      <span
        :id="
          selectedTheme == 'dark'
            ? 'light'
            : selectedTheme == 'light'
            ? 'auto'
            : selectedTheme == 'auto'
            ? 'dark'
            : 'light'
        "
        v-b-tooltip.hover.top="{
          title:
            selectedTheme === 'dark'
              ? $t('tooTip.dayMode')
              : selectedTheme === 'light'
              ? $t('tooTip.nightMode')
              : selectedTheme === 'auto'
              ? $t('tooTip.autoMode')
              : $t('tooTip.dayMode'),
          customClass: 'my-tooltip-nav'
        }"
      >
        <feather-icon
          size="21"
          :icon="`${
            selectedTheme == 'dark'
              ? 'Sun'
              : selectedTheme == 'light'
              ? 'Moon'
              : selectedTheme == 'auto'
              ? 'Settings'
              : 'Sun'
          }Icon`"
        />
      </span>
      <!-- <b-tooltip
        variant="primary"
        :target="
          selectedTheme == 'dark'
            ? 'light'
            : selectedTheme == 'light'
            ? 'auto'
            : selectedTheme == 'auto'
            ? 'dark'
            : 'light'
        "
        :show.sync="showTooltip"
        @shown="hideTooltipLater"
        >{{
          selectedTheme == "dark"
            ? $t("tooTip.dayMode")
            : selectedTheme == "light"
            ? $t("tooTip.nightMode")
            : selectedTheme == "auto"
            ? $t("tooTip.autoMode")
            : $t("tooTip.dayMode")
        }}</b-tooltip
      > -->
    </b-nav-item>
  </span>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem, VBTooltip } from "bootstrap-vue";

export default {
  components: {
    BNavItem,
    VBTooltip
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      showTooltip: false,
      selectedTheme: "auto"
    };
  },
  mounted() {
    this.selectedTheme = localStorage.getItem("SELECTED_THEME_MODE");
    if (this.selectedTheme === "auto") {
      if (window && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.skin = "dark";
      } else {
        this.skin = "light";
      }
    } else {
      this.skin = this.selectedTheme === "light" ? "light" : "dark";
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", ({ matches }) => {
        if (localStorage.getItem("SELECTED_THEME") == "auto") {
          if (matches) {
            this.skin = "dark";
          } else {
            this.skin = "light";
          }
          localStorage.setItem("SELECTED_THEME", this.skin);
        }
      });
  },
  beforeDestroy() {
    window.removeEventListener("change", (event) => {});
  },
  methods: {
    hideTooltipLater() {
      setTimeout(() => {
        this.showTooltip = false;
      }, 2000);
    },
    hideToolTip() {
      this.skin = this.isDark ? "light" : "dark";
      this.showTooltip = true;
      this.selectedTheme =
        this.selectedTheme == "dark"
          ? "light"
          : this.selectedTheme == "light"
          ? "auto"
          : this.selectedTheme == "auto"
          ? "dark"
          : "light";
      if (this.selectedTheme === "auto") {
        if (
          window &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.skin = "dark";
        } else {
          this.skin = "light";
        }
      } else {
        this.skin = this.selectedTheme === "light" ? "light" : "dark";
      }
      localStorage.setItem("SELECTED_THEME", this.selectedTheme);
      localStorage.setItem("SELECTED_THEME_MODE", this.selectedTheme);
    }
  }
};
</script>
<style lang="scss">
.my-tooltip-nav {
  white-space: nowrap;
  z-index: 99999 !important;
}
</style>
