<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <span class="d-flex align-items-center">
        <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
        />
        <span class="ml-50 text-body">{{
          currentLocale.name.slice(0, 2).toUpperCase()
        }}</span>
      </span>
    </template>

    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="updateLanguage(localeObj.locale)"
      class="d-flex align-items-center"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50 min-w-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { localeChanged, localize } from "vee-validate";
import constants from "@/utils/constants";
import store from "@/store";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales =
      JSON.parse(localStorage.getItem("LANGUAGES")) ||
      constants.SUPPORTED_LANGUAGES;
    /* eslint-disable global-require */
    // console.log(locales);
    return {
      locales
    };
  },
  mounted() {
    setInterval(() => {
      // const local =
      //   JSON.parse(localStorage.getItem("LANGUAGES")) ||
      //   constants.SUPPORTED_LANGUAGES;
      this.locales =
        JSON.parse(localStorage.getItem("LANGUAGES")) ||
        constants.SUPPORTED_LANGUAGES;
    }, 1000);

    /* eslint-disable global-require */
  },
  methods: {
    updateLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", this.$i18n.locale);
      localize(this.$i18n.locale);

      store.commit("appConfig/TOGGLE_RTL", { layout: { isRTL: true } });
      this.$router.go();
    }
  }
};
</script>

<style></style>
