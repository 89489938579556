var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container d-flex content align-items-center"},[_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{staticClass:"nav-link",on:{"click":_vm.toggleVerticalMenuActive}},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"}})],1)],1)]),_c('app-breadcrumb',{staticClass:"account-name"}),_c('div',{staticClass:"bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"}),_c('b-navbar-nav',{staticClass:"nav align-items-center ml-auto"},[_c('dark-Toggler',{staticClass:"d-none dark-toggle d-lg-block"}),_c('locale'),_c('b-nav-item-dropdown',{staticClass:"dropdown-user access-on-all-pages",attrs:{"right":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-sm-flex d-none user-nav"},[_c('p',{staticClass:"user-name font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.accountName)+" ")]),_c('span',{staticClass:"user-status"},[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" "+_vm._s(_vm.roleName ? ("(" + _vm.roleName + ")") : ""))])]),_c('b-avatar',{staticClass:"badge-minimal user-profile",attrs:{"size":"40","variant":"light-primary","badge":"","src":_vm.user.profile_pic_url,"text":_vm.user.first_name &&
            _vm.user.first_name.substring(0, 1) +
              (_vm.user.last_name && _vm.user.last_name
                ? _vm.user.last_name.substring(0, 1)
                : ''),"badge-variant":"success"}})]},proxy:true}])},[_c('div',{staticClass:"display-accout-name text-primary"},[_vm._v(" "+_vm._s(_vm.accountName)+" ")]),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{ name: 'profile' }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"UserIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.Profile")))])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{ name: 'user-invitations' }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"MailIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.Invitations")))])],1),(_vm.accounts && _vm.accounts.length > 1)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.getUserAccounts(1, 1)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"RefreshCcwIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.SwitchAccount")))])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center","to":{ name: 'settings' }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LockIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.ChangePassword")))])],1),(
          _vm.getCurrentAccount() === 'DEALER'
            ? _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                subject: _vm.constants.PERMISSIONS_MODEL.ACCOUNT
              })
            : _vm.getCurrentAccount() === 'CONSUMER'
            ? _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                subject: _vm.constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
              })
            : false
        )?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center account-setting","to":{
          name:
            _vm.getCurrentAccount() === 'DEALER' &&
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
              subject: _vm.constants.PERMISSIONS_MODEL.ACCOUNT
            })
              ? 'account-update'
              : _vm.getCurrentAccount() === 'CONSUMER' &&
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                  subject: _vm.constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                })
              ? 'consumer-account-update'
              : ''
        }}},[_c('AccountSetting',{staticClass:"acc-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.AccountSettings")))])],1):_vm._e(),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-active-sessions",modifiers:{"modal-active-sessions":true}}],attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.activeSessions}},[_c('ActiveSessionsIcon',{staticClass:"acc-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.ActiveSessions")))])],1),_c('ActiveSessions',{attrs:{"activeSessionsOpen":_vm.activeSessionsOpen},on:{"activeSessions":_vm.activeSessions}}),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("navbar.Logout")))])],1)],1)],1),_c('UserOnboarding',{attrs:{"inviteData":_vm.inviteData,"joinWithUserInvite":_vm.joinWithUserInvite}}),_c('SwitchAccount',{attrs:{"accounts":_vm.accounts,"isSelectedId":_vm.isSelectedId,"mouseout":_vm.mouseout,"mouseover":_vm.mouseover,"selectedUserAccount":_vm.selectedUserAccount}}),_c('Loader',{attrs:{"show":_vm.show}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }