<template>
  <b-modal
    id="modal-sm-switch-account"
    no-close-on-esc
    no-close-on-backdrop
    centered
    modal-class="no-header-modal"
    :hide-header="true"
  >
    <div style="padding-top: 10px">
      <div class="slect-account-title">{{ $t("profile.SelectAccount") }}</div>
      <div class="select-account-scroll">
        <div v-for="account in accounts" :key="account.account_id">
          <div
            :class="
              isSelectedId == account.account_id
                ? `select-account-row ${
                    account.white_labeling && account.white_labeling.theme
                      ? account.white_labeling.theme
                      : ''
                  } active-primary`
                : `select-account-row ${
                    account.white_labeling && account.white_labeling.theme
                      ? account.white_labeling.theme
                      : ''
                  }`
            "
            @mouseover="mouseover(account.account_id)"
            @mouseout="mouseout(account.account_id)"
            @click="selectedUserAccount(account, true)"
            style="cursor: pointer"
          >
            <div class="text-row">
              <div class="media-body">
                <feather-icon icon="UserIcon" size="16" />
                <small class="text-body mr-left-10">{{
                  account.type == "CONSUMER"
                    ? $t("profile.Consumer")
                    : $t("profile.Dealer")
                }}</small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-body">{{ account.role }}</small>
              </div>
              <small class="text-body"
                >{{ $t("profile.Created") }}
                {{ getFormattedDate(account.u_a_ctime) }}</small
              >
            </div>
            <b-media class="align-item-center top-10">
              <template #aside>
                <b-avatar
                  size="36"
                  src="RA"
                  :text="account.avtar_txt"
                  :variant="account.variant"
                />
              </template>
              <h6 class="mb-0">{{ account.account_name }}</h6>
              <small class="text-muted">{{ account.email }}</small>
            </b-media>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import constants from "@/utils/constants";
import { BCard, BButton, BMedia, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BMedia,
    BAvatar
  },
  props: [
    "accounts",
    "isSelectedId",
    "mouseout",
    "mouseover",
    "selectedUserAccount"
  ],
  data() {
    return {
      activeUser: "select-account-row active-primary"
    };
  },

  methods: {
    getFormattedDate(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.slect-account-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  text-align: left;
  color: $headings-color;
  margin-top: -15px;
  margin-bottom: 33px;
}
#modal-sm-switch-account {
  .slect-account-title {
    margin-bottom: 15px;
    margin-top: -5px;
  }
}
#modal-sm-switch-account___BV_modal_outer_ {
  z-index: 9999 !important;
}
</style>
