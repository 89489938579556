<template>
  <b-modal
    id="modal-accept-invite"
    centered
    size="sm"
    modal-class="no-header-modal"
  >
    <b-card-text>
      <div class="d-flex justify-content-center">
        <b-avatar variant="light-primary" size="96" class="size-96">
          <feather-icon size="37" icon="UserPlusIcon" />
        </b-avatar>
      </div>
      <div class="new-invite-title">{{ $t("user.NewInvite") }}</div>
      <div class="you-invite">
        {{
          $t("user.YouInvite", {
            role: inviteData ? inviteData.role : ""
          })
        }}
      </div>
      <div class="invite-name">
        "{{ inviteData && inviteData.account_name }}"
      </div>
      <div class="d-flex justify-content-center mb-64 mtt-50">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="joinWithUserInvite('ACCEPT')"
        >
          {{ $t("user.Accept") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click="joinWithUserInvite('DECLINE')"
        >
          {{ $t("user.Decline") }}
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
</template>
<script>
import constants from "@/utils/constants";
import { BCard, BButton, BMedia, BAvatar, BCardText } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple
  },
  components: {
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BCardText
  },
  props: ["joinWithUserInvite", "inviteData"],
  data() {
    return {};
  },

  methods: {
    getFormattedDate(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 32px;
}
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}
</style>
